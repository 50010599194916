import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../data/store';
import { useNavigate } from 'react-router-dom';
import Button from './common/buttons/Button';
import Config from '../config';
import { BackgroundBeams } from './ui/background-beams';
import { styled } from 'styled-components';
import AstronautImage from '../images/astronauts.jpeg';
import Paper from './common/layout/Paper';
import Link from './common/Link';

export default function LoginScreen() {
	const navigate = useNavigate();
	const authState = useSelector((state: RootState) => state.authState);

	useEffect(() => {
		if (authState.astroToken) {
			navigate('/');
		}
	}, [authState, navigate]);
	const SCOPES =
		'email profile https://www.googleapis.com/auth/chat.spaces https://www.googleapis.com/auth/chat.messages https://www.googleapis.com/auth/chat.memberships https://www.googleapis.com/auth/admin.directory.user.readonly https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/presentations https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/documents'; // Add other scopes as needed

	const handleLogin = () => {
		const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${encodeURIComponent(
			Config.googleOAuth.GOOGLE_CLIENT_ID
		)}&redirect_uri=${encodeURIComponent(
			Config.googleOAuth.REDIRECT_URI
		)}&scope=${encodeURIComponent(SCOPES)}&access_type=offline`;
		window.location.href = authUrl;
	};

	return (
		<Container>
			{/* <BackgroundBeams /> */}
			<ImageContainer></ImageContainer>
			<LoginContainer>
				<LoginBox>
					<AstroText>AstroAI</AstroText>
					<Button onClick={handleLogin}>Login</Button>
				</LoginBox>
				<Link
					style={{ position: 'absolute', bottom: 10 }}
					onClick={() =>
						window.open(
							'https://app.termly.io/policy-viewer/policy.html?policyUUID=43ae3e4d-d34b-4054-b683-460fec3453f3',
							'_blank'
						)
					}
				>
					Privacy Policy
				</Link>
			</LoginContainer>
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: row;
	height: 100vh;
	width: 100vw;
`;

const ImageContainer = styled.div`
	flex: 1;
	background-image: url(${AstronautImage});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
`;

const LoginContainer = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 350px;
`;

const LoginBox = styled(Paper)`
	padding: ${({ theme }) => theme.spacing(8)};
	position: absolute !important;
	top: 30%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
`;

const AstroText = styled.div`
	color: #f5f5f5;
	font-size: 3rem;
	margin-bottom: ${({ theme }) => theme.spacing(4)};
`;
